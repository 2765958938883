import axios from "axios";
import {api} from "@/main";


export const exam = {
    state: {
        accessToken:{
            token: "28|6JQ4Ht6iD2ArMCdCQ2kE3z0av09Zkt9Wsurlq8RG",
            id:2
        },
        userQuestions:'',
        examStarted:false,
        answer:[],
        examSettings:'',
        timer:0,
        examEnd:false,
        questionCount:0,
        instruction:'',
        jobTitle:'',
        status:'',
        result:''

    },
    getters: {

        getAccessToken:(state)=>state.accessToken,
        getUserQuestions:(state)=>state.userQuestions,
        getExamStatus:(state)=>state.examStarted,
        getAnswer:(state)=>state.answer,
        getExamSettings:(state)=>state.examSettings,
        getUserTimer:(state)=>state.timer,
        getExamEnd:(state)=>state.examEnd,
        getQuestionCount:(state)=>state.questionCount,
        getInstruction:(state)=>state.instruction,
        getJobTitle:(state)=>state.jobTitle,
        getCBTStatus:(state)=>state.status,
        getExamResult:(state)=>state.result



    },
    mutations: {
        updateAccessToken: (state, data) => state.accessToken = data,
        updateUserQuestions: (state, response) => state.userQuestions = response,
        updateExamStatus: (state) => state.examStarted = !state.examStarted,
        updateAnswer: (state, data) => {
            let checker = state.answer.find(item => item.question_id === data.question_id)
            if (checker !== undefined) return checker.option_selected = data.option_selected
            state.answer.push({
                question_id: data.question_id,
                option_selected: data.option_selected,
            })

        },
        updateExamSettings: (state, response) => state.examSettings = response,
        updateUserTimer: (state, newTimer) => state.timer = newTimer,
        updateCountDown: (state,data)=>{
            if(parseInt(state.timer) === 0) return
            state.timer -= data
        },
        updateEndExam:(state)=>state.examEnd= !state.examEnd,
        updateQuestionCount:(state,data)=>state.questionCount= data,
        updateInstruction:(state,data)=>state.instruction= data,
        updateJobTitle:(state,data)=>state.jobTitle= data,
        updateCBTStatus:(state,data)=>state.status= data,
        updateResult:(state,data)=>state.result=data

    },
    actions: {
        fetchUserQuestions: async (context,data)=>{
            await axios.post(`${api}/start-quiz`,{
                app_id:context.getters.getAccessToken.id,
                job_id:sessionStorage.current_job_id
            },{
                headers: {Authorization: `Bearer ${context.getters.getAccessToken.token}`}
            })
                .then(response=>{
                    if(response.data.status){
                         context.commit('updateUserQuestions',response.data.data.questions)
                         context.commit('updateUserTimer',response.data.data.timer)
                         context.commit('updateQuestionCount',response.data.data.totalQ)
                         context.commit('updateJobTitle',response.data.data.JobTitle)
                    }
                }).catch(e=>console.log(e.message))
        },
        fetchExamInstruction: async (context,data)=>{
            await axios.get(`${api}/exam-instruction/${sessionStorage.current_job_id}`,{
                headers: {Authorization: `Bearer ${context.getters.getAccessToken.token}`}
            })
                .then(response=>{
                    if(response.data.status){
                         context.commit('updateInstruction',response.data.data)
                    }
                }).catch(e=>console.log(e.message))
        },
        fetchCBTStatus: async (context,data)=>{
            await axios.post(`${api}/cbt-stat`,{
                    app_id:context.getters.getAccessToken.id,
                    job_id:sessionStorage.current_job_id

            },
                {
                headers: {Authorization: `Bearer ${context.getters.getAccessToken.token}`}
        })
                .then(response=>{
                    if(response.data.status){
                         context.commit('updateCBTStatus',response.data.data)
                    }
                }).catch(e=>console.log(e.message))
        },

        timeCountDown:async (context)=>{
            setInterval(async()=>{
                if(context.getters.getUserTimer===0)return
                if(context.getters.getUserTimer <=1){
                    clearInterval(context.state.timer)
                    await context.commit('updateEndExam')
                    await context.dispatch('endExam')
                    return
                }
                context.commit('updateCountDown',1)


            },1000)
        },
        submitAnswers:async(context,data)=> {
            try {
                await axios.post(`${api}/submit-exam`, {
                    app_id: context.getters.getAccessToken.id,
                    answers: context.getters.getAnswer,
                    job_id: sessionStorage.current_job_id
                }, {
                    headers: {Authorization: `Bearer ${context.getters.getAccessToken.token}`}
                })
                    .then(response => {
                        if (response.data.status) return context.commit('updateResult', response.data)
                    })
                    .catch(e=>console.log(e.message))
            } catch (e) {
                console.log(e.message)
            }

        },
        FetchExamResult:async(context)=> {
            try {
                await axios.post(`${api}/check-result`, {
                    app_id: context.getters.getAccessToken.id,
                    job_id: sessionStorage.current_job_id
                }, {
                    headers: {Authorization: `Bearer ${context.getters.getAccessToken.token}`}
                })
                    .then(response => {
                        if (response.data.status) return context.commit('updateResult', response.data)
                    })
                    .catch(e=>console.log(e.message))
            } catch (e) {
                console.log(e.message)
            }

        },

        endExam: async (context)=>{
            await context.dispatch('submitAnswers')
             await context.dispatch('UpdateExamStatus',{
                job_id: sessionStorage.current_job_id
            })
            await  context.dispatch('fetchCBTStatus')
            // window.location.reload()


        },
        UpdateExamStatus:async (context,data)=>{
         return   await axios.post(`${api}/update-cbt-stat`,{
                job_id:sessionStorage.current_job_id,
                app_id:context.getters.getAccessToken.id,
            },{
                headers: {Authorization: `Bearer ${context.getters.getAccessToken.token}`}
            })
        }

    },
}