<template>
  <v-app style="background-color: #F4F5F8!important">
    <router-view/>
  </v-app>
</template>

<script>

import('@/assets/css/w3.css')
export default {
  name: 'App',

  data: () => ({
    //
  }),
};
</script>
<style>

</style>


