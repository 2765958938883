const userRoutes = [
    {
        name:'home',
        path:'/',
        component:()=>import(/*webpackChunkName: "index" */ '../views/home'),
        meta:{
            title:'Home - IBEDC Recruitment Portal'
        }
    },
    {
        name:'jobs',
        path:'/jobs',
        query:'page=all',
        component:()=>import(/*webpackChunkName: "job" */ '../views/jobs'),
        meta:{
            title:'Job Listing - IBEDC Recruitment Portal'
        }
    },
    {
        name:'search',
        path:'/search-job',
        component:()=>import(/*webpackChunkName: "search-job" */ '../views/search'),
        meta:{
            title:'Search Job- IBEDC Recruitment Portal'
        }
    },
    {
        name:'contact',
        path:'/contact-us',
        component:()=>import(/*webpackChunkName: "contact" */ '../views/contact'),
        meta:{
            title:'Contact Us - IBEDC Recruitment Portal'
        }
    },
    {
        name:'login',
        path:'/login',
        component:()=>import(/*webpackChunkName: "login" */ '../views/login'),
        meta:{
            title:'Login - IBEDC Recruitment Portal'
        }
    },
    {
        name:'forgotPassword',
        path:'/forgot-password',
        component:()=>import(/*webpackChunkName: "forgot-password" */ '../views/forgotPassword'),
        meta:{
            title:'Forgot Password - IBEDC Recruitment Portal'
        }
    },
    {
        name:'resetPassword',
        path:'/reset-password/:enc/:id/:uniqid',
        component:()=>import(/*webpackChunkName: "reset-password" */ '../views/resetPassword'),
        meta:{
            title:'Reset Password - IBEDC Recruitment Portal'
        }
    },
    {
        name:'auth-login',
        path:'/auth-login',
        component:()=>import(/*webpackChunkName: "auth-login" */ '../views/auth-login'),
        meta:{
            title:'Authenticating Staff - IBEDC Recruitment Portal'
        }
    },
    {
        name:'register',
        path:'/create-account',
        component:()=>import(/*webpackChunkName: "register" */ '../views/register'),
        meta:{
            title:'Create Account - IBEDC Recruitment Portal'
        }
    },
    {
        name:'profile',
        path:'/user/profile',
        component:()=>import(/*webpackChunkName: "user-profile" */ '../views/user/profile'),
        meta:{
            title:'My Profile- IBEDC Recruitment Portal',
            auth:'user'
        }
    },
    {
        name:'my-jobs',
        path:'/user/jobs',
        component:()=>import(/*webpackChunkName: "user-jobs" */ '../views/user/my-jobs'),
        meta:{
            title:'My Jobs- IBEDC Recruitment Portal',
            auth:'user'
        }
    },
    {
        name:'jobDetails',
        path:'/jobs/:slug',
        component:()=>import(/*webpackChunkName: "user-job-details" */ '../views/jobDetails'),
        meta:{
            title:'Job Details- IBEDC Recruitment Portal'
        },
        params:true,
    },
    {
        name:'profile-completion',
        path:'/user/complete-profile',
        component:()=>import(/*webpackChunkName: "user-profile-completion" */ '../views/user/profile-completion'),
        meta:{
            title:'Complete Profile- IBEDC Recruitment Portal',
            auth:'user'
        }
    },
    {
        name:'settings',
        path:'/user/settings',
        component:()=>import(/*webpackChunkName: "user-settings" */ '../views/user/settings'),
        meta:{
            title:'Account Settings - IBEDC Recruitment Portal',
            auth:'user'
        }
    },
    {
        name:'exam',
        path:'/cbt/exam',
        component:()=>import(/*webpackChunkName: "user-exam" */ '../views/cbt/exam'),
        meta:{
            title:'Recruitment Exam - IBEDC Recruitment Portal',
            auth:'user'
        }
    },

    {
        name:'notFound',
        path:'*',
        component:()=>import(/*webpackChunkName: "error" */ '../views/notFound'),
        meta:{
            title:'Error 404- IBEDC Recruitment Portal',

        }
    },
]

export default userRoutes