import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'

export const api = `https://adcareer.ibedc.com/api`
export const bus = new Vue();
Vue.config.productionTip = false

//set the title of each webpage on routing and redirect to the top
router.beforeEach((toRoute, fromRoute, next) => {
  window.document.title = toRoute.meta && toRoute.meta.title ? toRoute.meta.title : 'IBEDC Recruitment Portal';
  window.scroll(0,0)

  next();
})

//verify access on routing to each page
router.beforeEach((to, from, next) => {

  if (to.meta.auth === 'user' && store.getters.getAuth.id===undefined) next({ name: 'login' });


  else next()
})

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
